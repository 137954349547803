import { AppModulesAPI } from './appModulesFactory'
import _ from 'lodash'
import { AppPublicApiUtils } from '@wix/thunderbolt-symbols'

export function createAppsPublicAPIsFactory({
	handlers,
	importScripts,
	pageId,
	moduleRepoUrl,
	appModulesAPI
}: {
	handlers: any
	importScripts: any
	pageId: string
	moduleRepoUrl: string
	appModulesAPI: AppModulesAPI
}): AppPublicApiUtils {
	appModulesAPI.getAllPublicApisOnPage().then((publicApis) => {
		if (process.env.browser) {
			handlers.registerPublicApiGetter(async () => {
				if (!self.pmrpc) {
					await importScripts(`${moduleRepoUrl}/pm-rpc@2.0.0/build/pm-rpc.min.js`, 'pm-rpc')
				}

				// TODO if we see that IFrames request APIs of apps on site that are not on page,
				// we will appExportsProvider.getAppExports() all apps on site, and deprecate the publicAPIs parameter.
				return _.map(publicApis, (publicAPI, appDefinitionId) => {
					const name = `viewer_platform_public_api_${appDefinitionId}_${pageId}`
					self.pmrpc.api.set(name, publicAPI)
					return name
				})
			})
		}
	})

	return {
		getPublicAPI(appDefinitionId) {
			return appModulesAPI.getPublicApi(appDefinitionId)
		}
	}
}
