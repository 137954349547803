import { createPromise } from '@wix/thunderbolt-commons'
import { PublicAPI } from '@wix/thunderbolt-symbols'
import _ from 'lodash'
import { ModelsAPI } from './types'
import { ClientSpecMapAPI } from './clientSpecMapService'

type RunApplicationFunc = (appDefinitionId: string) => void
export type AppModulesAPI = {
	setPublicApi: (appDefinitionId: string, appModule: PublicAPI) => void
	getAllPublicApisOnPage: () => Promise<{ [appDefinitionId: string]: PublicAPI }>
	getPublicApi: (appDefinitionId: string) => Promise<PublicAPI>
	registerRunApplication: (_runApplicationFunc: RunApplicationFunc) => void
}
type PublicApisPromises = { [appDefinitionId: string]: { setPublicApi: (api: PublicAPI) => void; publicApiPromise: Promise<PublicAPI> } }

const createPromiseForApp = () => {
	const { resolver, promise } = createPromise()
	return { publicApiPromise: promise, setPublicApi: resolver }
}

export function AppModulesApiFactory({ modelsApi, clientSpecMapApi }: { modelsApi: ModelsAPI; clientSpecMapApi: ClientSpecMapAPI }): AppModulesAPI {
	const publicApisPromises: PublicApisPromises = _.mapValues(modelsApi.getApplications(), createPromiseForApp)
	let runApplicationFunc: RunApplicationFunc
	return {
		setPublicApi(appDefinitionId: string, publicApi: PublicAPI) {
			publicApisPromises[appDefinitionId].setPublicApi(publicApi)
		},
		async getAllPublicApisOnPage() {
			const appPublicApisArray = _.map(publicApisPromises, (appPublicApi, appDefinitionId) => appPublicApi.publicApiPromise.then((publicApi) => ({ [appDefinitionId]: publicApi })))
			const appPublicApis = await Promise.all(appPublicApisArray)
			return Object.assign({}, ...appPublicApis)
		},
		async getPublicApi(appDefinitionId: string) {
			if (!clientSpecMapApi.isAppOnSite(appDefinitionId)) {
				throw new Error(`getPublicAPI() of ${appDefinitionId} failed. The app does not exist on site.`)
			}
			if (!publicApisPromises[appDefinitionId]) {
				publicApisPromises[appDefinitionId] = createPromiseForApp()
				if (!runApplicationFunc) {
					// TODO: report error to sentry
					throw new Error(`getPublicAPI() of ${appDefinitionId} failed`)
				}
				runApplicationFunc(appDefinitionId)
			}
			return publicApisPromises[appDefinitionId].publicApiPromise
		},
		registerRunApplication(_runApplicationFunc: RunApplicationFunc) {
			runApplicationFunc = _runApplicationFunc
		}
	}
}
