import { PlatformUtils, SdkInstance, PlatformLogger, Connection, $W, PlatformEnvData, $WScope, EventContext } from '@wix/thunderbolt-symbols'
import { componentSdkFactoryArgs } from '@wix/thunderbolt-platform-types'
import { ModelsAPI } from './types'
import { ViewerAPI } from '../types'
import { IPlatformAnimationsAPI, RunAnimationOptions } from '../animations-types'
import { CreateSetProps } from './setPropsFactory'
import { MasterPageId } from './constants'
import { InstanceCacheFactory } from './instanceCache'
import { ComponentSdkStateFactory } from './componentSdkState'
import { WixSelector } from './wixSelector'
import { RegisterEventFactory } from './createRegisterEvent'
import { getItemId, isDisplayedOnly } from '@wix/thunderbolt-commons'

export type SdkFactoryParams = {
	getSdkFactoryParams(args: {
		compId: string
		controllerCompId: string
		getChildren: () => Array<SdkInstance>
		connection?: Connection
		compType: string
		role: string
		getInstance: WixSelector['getInstance']
		create$w: () => $W
		$wScope: $WScope
	}): componentSdkFactoryArgs
}

export default function({
	modelsApi,
	viewerAPI,
	getCompRefById,
	platformUtils,
	createSdkHandlers,
	createSetProps,
	sdkInstancesCache,
	componentSdkState,
	registerEventFactory,
	animationsApi,
	platformEnvData
}: {
	modelsApi: ModelsAPI
	viewerAPI: ViewerAPI
	getCompRefById: (compId: string) => any
	platformUtils: PlatformUtils
	createSdkHandlers: (pageId: string) => any
	logger: PlatformLogger
	createSetProps: CreateSetProps
	sdkInstancesCache: InstanceCacheFactory
	componentSdkState: ComponentSdkStateFactory
	registerEventFactory: RegisterEventFactory
	animationsApi: IPlatformAnimationsAPI
	platformEnvData: PlatformEnvData
}) {
	function getSdkFactoryParams({
		compId,
		getChildren,
		connection,
		compType,
		controllerCompId,
		role,
		getInstance,
		create$w,
		$wScope
	}: {
		compId: string
		controllerCompId: string
		getChildren: () => Array<SdkInstance>
		connection: Connection
		compType: string
		role: string
		getInstance: WixSelector['getInstance']
		create$w: () => $W
		$wScope: $WScope
	}) {
		const props = modelsApi.getCompProps(compId)
		const sdkData = modelsApi.getCompSdkData(compId)
		const handlers = createSdkHandlers(modelsApi.getPageIdByCompId(compId))

		const { hiddenOnLoad, collapseOnLoad } = modelsApi.getOnLoadProperties(compId)

		function getSdkInstance() {
			const itemId = isDisplayedOnly(compId) ? getItemId(compId) : undefined
			return sdkInstancesCache.getSdkInstance({ compId, controllerCompId, role, itemId })
		}

		const isGlobal = () => {
			if (modelsApi.getCompType(compId) === 'Page') {
				return true // Page components are always global by design
			}
			return modelsApi.getPageIdByCompId(compId) === MasterPageId
		}

		function getParent(childId: string = compId): SdkInstance | null {
			const parentId = modelsApi.getParentId(childId)
			if (!parentId) {
				return
			}
			const parentCompType = modelsApi.getCompType(parentId) as string
			const parentRole = modelsApi.getRoleForCompId(parentId, controllerCompId)
			if (!parentRole) {
				// becky structure hierarchy might be different than the document structure.
				return getParent(parentId)
			}
			return getInstance({ controllerCompId, compId: parentId, compType: parentCompType, role: parentRole })
		}

		const getOwnSdkInstance = (_compId: string = compId) => getInstance({ controllerCompId, compType, connection, role, compId: _compId })
		const registerEvent = registerEventFactory.createRegisterEvent(compId, getOwnSdkInstance)
		const createEvent = registerEventFactory.getCreateEventFunction(getOwnSdkInstance)

		function setStyles(style: object) {
			if (modelsApi.isComponentInsideRepeater(compId)) {
				modelsApi.getDisplayedIdsOfRepeaterTemplate(compId).forEach((displayedId: string) => {
					viewerAPI.updateStyles({ [displayedId]: style })
				})
				return
			}
			viewerAPI.updateStyles({ [compId]: style })
		}

		function createScoped$w({ context }: { context?: EventContext } = {}) {
			const $w = create$w()
			return context ? $w.at(context) : $w
		}

		const wixCodeId = modelsApi.getRoleForCompId(compId, 'wixCode')

		return {
			props,
			sdkData,
			compId,
			controllerCompId,
			setStyles,
			setProps: createSetProps(compId),
			createSdkState: componentSdkState.createSdkState(compId),
			compRef: getCompRefById(compId),
			handlers,
			getChildren,
			registerEvent,
			createEvent,
			getSdkInstance,
			role,
			runAnimation: (options: Omit<RunAnimationOptions, 'compId'>) => animationsApi.runAnimation({ ...options, compId }),
			create$w: createScoped$w,
			$wScope,
			metaData: {
				compId,
				role,
				connection,
				compType,
				isGlobal,
				hiddenOnLoad,
				collapsedOnLoad: collapseOnLoad,
				isRendered: () => modelsApi.isRendered(compId),
				getParent,
				getChildren,
				wixCodeId
			},
			platformEnvData,
			// eventually will remove the spread after migrating in EE, since we want SDKs to use platformUtils just like namespaces
			platformUtils,
			...platformUtils
		}
	}
	return {
		getSdkFactoryParams
	}
}
